import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import ProjectList from '../../components/ProjectList';
import { Wrapper, Back, Columns, Left, Right, Top, Bottom } from './style';

const Page = ({ pageContext, data }) => {
  const { metaData } = pageContext;
  const page = get(data, 'contentfulPage');

  return (
    <Wrapper background={page.backgroundColour}>
      <Helmet title={`${page.name} | ${metaData.siteName}`} />
      {/* <Back>
        <Link to="/">&larr; Back to Projects</Link>
      </Back> */}
      <Columns>
        <Left>
          <Top
            dangerouslySetInnerHTML={{
              __html: page.body.childMarkdownRemark.html,
            }}
          />
          <Bottom>
            <ul>
              {page.links.map(link => (
                <li key={link.id}>
                  <a href={link.url} target="_blank" rel="noreferrer noopener">
                    {link.label}
                  </a>
                </li>
              ))}
            </ul>
          </Bottom>
        </Left>
        <Right>
          <ProjectList items={page.projectsList} />
        </Right>
      </Columns>
    </Wrapper>
  );
};

export default Page;

export const pageQuery = graphql`
  query PageQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      name
      slug
      backgroundColour
      body {
        childMarkdownRemark {
          html
        }
      }
      links {
        id
        label
        url
      }
      projectsList {
        id
        label
        project {
          slug
          thumbnail {
            file {
              details {
                image {
                  height
                  width
                }
              }
            }
            fluid(maxWidth: 300, maxHeight: 300, quality: 100) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
