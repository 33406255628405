import styled from 'styled-components';
import { layout, typography, layers, breakpoints } from '../../theme';

export const Wrapper = styled.div`
  background: ${props => props.background};
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${layers.PAGE};
`;

export const Back = styled.div`
  left: ${layout.SPACING_X}rem;
  position: fixed;
  top: ${layout.SPACING_Y}rem;
`;

export const Columns = styled.div`
  display: flex;
  font-size: ${typography.FONT_SIZE__LARGE}rem;
  line-height: ${typography.LINE_HEIGHT__LARGE}rem;
  padding: ${layout.SPACING_Y + layout.SPACING * 2}rem ${layout.SPACING_X}rem 0;

  @media ${breakpoints.MOBILE_MQ} {
    display: block;
    font-size: ${typography.FONT_SIZE}rem;
    line-height: ${typography.LINE_HEIGHT}rem;
    padding: ${layout.SPACING_Y / 2 + layout.SPACING * 2}rem
      ${layout.SPACING_X / 2}rem 0;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${layout.SPACING * 1.5}rem;
  min-height: calc(100vh - ${layout.SPACING_Y}rem - ${layout.SPACING * 2}rem);
  min-width: 25rem;
  width: 33.3333%;

  @media ${breakpoints.MOBILE_MQ} {
    display: block;
    margin: 0;
    min-height: 0;
    min-width: 0;
    width: auto;
  }
`;

export const Right = styled.div`
  flex-grow: 1;
  margin-left: ${layout.SPACING * 1.5}rem;
  margin-bottom: ${layout.SPACING_Y}rem;

  @media ${breakpoints.MOBILE_MQ} {
    margin: 0;
    padding-bottom: ${layout.SPACING_Y}rem;
  }
`;

export const Top = styled.div`
  flex-grow: 1;
  margin-bottom: ${layout.SPACING}rem;

  @media ${breakpoints.MOBILE_MQ} {
    margin-bottom: ${typography.LINE_HEIGHT}rem;
  }
`;

export const Bottom = styled.div`
  margin-bottom: ${layout.SPACING_Y}rem;

  @media ${breakpoints.MOBILE_MQ} {
    margin-bottom: ${typography.LINE_HEIGHT}rem;
  }
`;
